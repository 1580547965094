
// Team function
$(document).ready( function() {



$.getJSON( "assets/json/media.json", function(data){

  var media_container = $('#media-container');
  var media_hided = $('#media-hided');
  var members_first = $('#lab-team');
  var obj = data.medias[0];
  for (var index in obj) {
    var div_media_hided = $('<div class="rows   team-input" '+'id=' + index + '>' + '</div>').appendTo(media_hided);
    var items = obj[index];
          for (var i = 0; i < items.length; i++) {
              div_img = (items[i].img)?"<img src=\"" +items[i].img+ "\" alt=\"" +items[i].alt+ "\">":"" ;
              div_link = 'href="#'+index+'"';
              div_frame = '<iframe width="420" height="315" src="'+ items[i].video_url +'" frameborder="0" allowfullscreen> </iframe>';
              div_video = (items[i].video_url)?'<div class="youtube  flex-video widescreen">' + div_frame + '</div>':"";
                div_wrapper = '<div class="mix  team small-6 medium-4 large-3 end column  ' + items[i].categorie +' media-wrapper"'+ div_link +   '>' + div_img + div_video + '</div>';

              $( div_wrapper ).appendTo(media_container);


              div_name = (items[i].alt)?'<h5>'+items[i].alt+'</h5>':'<h5>'+items[i].video_name+'</h5>';
              div_frameBox = '<iframe width="420" height="315" src="'+ items[i].video_url +'" frameborder="0" allowfullscreen> </iframe>';
              div_videoBox = (items[i].video_url)?'<div class="youtubeBox flex-video widescreen">' + div_frameBox + '</div>':"";
              div_description ='<p>'+items[i].description+'</p>';
              div_des = '<div class="row column">' + div_name + div_description+'</div>';
              div_img_container = (items[i].img)?' <div>' + div_img +'</div>':"";


              $( div_img_container +  div_videoBox + div_des ).appendTo(div_media_hided);


              $(".mix").colorbox({inline:true, rel:'mix', slideshow:false, maxWidth:'80%', maxHeight:'100%'});
              $('#media-container').mixItUp();
              $('#media-container').mixItUp('filter', '.mix');




 }
 }
});

});
