$(document).foundation();

$('.partner_slider').owlCarousel({
    autoplay:true,
   autoplayTimeout:9000,
   autoplayHoverPause:true,
    loop:true,
    margin:10,
    nav:false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1200:{
            items:4
        }
    }
})

$('.certifiso, .certifn, .certifinter').owlCarousel({
    autoplay:true,
   autoplayTimeout:9000,
   autoplayHoverPause:true,
    loop:true,
    margin:10,
    nav:false,
    lazyLoad: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1200:{
            items:3
        }
    }
})

$('.intro_media').owlCarousel({
    autoplay:true,
   autoplayTimeout:4000,
   autoplayHoverPause:true,
    loop:true,
    margin:10,
    nav:false,
    lazyLoad: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1200:{
            items:1
        }
    }
})
