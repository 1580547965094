// Team function
$(document).ready( function() {

$.getJSON( "assets/json/team.json", function(data){

  var member_first = $('#memberFirst');
  var members = $('#membres');
  var members_first = $('#lab-team');
  var obj = data.stuffs[0];
  for (var index in obj) {
    var equip = $('<div class="rows   team-input" '+'id=' + index + '>' + '</div>').appendTo(members);
    var items = obj[index];
          for (var i = 0; i < items.length; i++) {
              div_link = 'href="#'+index+'"';
              div_style =   ' style="background-image:url('+  items[i].img_cover +');"' ;
              div_nameh5 =  '<h5>'+ items[i].name + '</h5>' ;
              div_functionh6 = '<h6 class="subheader">'+ items[i].function + '</h6>';
              text_wrapper = '<div class="text_wrapper">'+ div_nameh5 + div_functionh6 + '</div>';
              div_wrapper = '<div class="team  team-wrapper"'+div_link+ div_style +  '>' + text_wrapper + '</div>';
              div_member = '<div class=" medium-3 end column">' + div_wrapper + '</div> ';
              $( div_member ).appendTo(member_first);

              notation = ' | LAB-TEAM | LAB AMIN CHOUCH 2016';
              div_img = (items[i].img)?"<img src=\"" +items[i].img+ "\" alt=\"" +items[i].name + notation + "\">":"" ;
              div_img_container =  ' <div class=" img_div large-6 column">' + div_img +'</div>';
              div_name = ' <div class="large-5 medium-12 end column team-text">' +'<h4>'+items[i].name+'</h4>';
              div_function = '<h5>'+items[i].function+'</h5>' + '<hr class="tm-dotted">';
              div_passion =  '<p><strong> Motivation : </strong>'+ items[i].passion+' </p>'+'<br>'+
              '<strong> Métier : </strong>' + items[i].job + ' </p>' +'</div>';

              $( div_img_container + div_name + div_function + div_passion ).appendTo(equip);

              $(document).ready(function(){
              $(".team").colorbox({inline:true, rel:'team', slideshow:false, maxWidth:'80%', maxHeight:'100%'});
              });
 }
 }
});
});
